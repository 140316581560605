const env = {
    integration:{
        API_BASE_URL:"https://serviskayit.viessmann.com.tr"
    },
    staging:{
        API_BASE_URL:"https://serviskayit.viessmann.com.tr"
    },
    production:{
        API_BASE_URL:"https://serviskayit.viessmann.com.tr"
    }
}


export default env.integration