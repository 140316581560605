import { createApp } from 'vue';
import App from './App.vue';
import env from './config.js';
import router from './router';
import store from './store';
import i18n from './lang';
import set from 'lodash/set';
import axios from './helpers/interceptors';
import mask from "vue-the-mask";
import { convertDate } from "./helpers/helpers"

import FormElement from './components/FormElement.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const app = createApp(App);
app.config.productionTip = false


app.component('FormElement', FormElement);

app.config.globalProperties = {
    $set: set,
    $env: env,
    $axios: axios,
    $convertDate:convertDate
};


app.use(i18n);
app.use(router);
app.use(store);
app.use(mask);
app.mount('#app');
