<template>
  <div
    class="form-group focus"
    :class="disabled ? 'disabled' : ''"
    v-if="type == 'text'"
  >
    <template v-if="masked == 'phone'">
      <input
        type="text"
        class="form-control"
        :id="name"
        placeholder=""
        v-model="inputVal"
        :disabled="disabled"
        @input="phoneMask"
      />
    </template>
    <template v-else>
      <input
        type="text"
        class="form-control"
        :id="name"
        placeholder=""
        v-model="inputVal"
        :disabled="disabled"
        v-mask="this[masked]"
        v-if="masked"
      />
      <input
        type="text"
        class="form-control"
        :id="name"
        placeholder=""
        v-model="inputVal"
        :disabled="disabled"
      />
    </template>
    <label :for="name"
      >{{ label }}
      <span class="masked-text" v-if="maskedMax && !hiderange">
        {{
          inputVal.length >= maskedMax
            ? maskedMax + " - " + maskedMax
            : inputVal.length + " - " + maskedMax
        }}
      </span>
    </label>
  </div>
  <div class="form-group focus long"  v-if="type == 'textarea'">
    <textarea
      :id="name"
      class="form-control"
      placeholder=""
      v-model="inputVal"
    ></textarea>
    <label :for="name">{{ label }}</label>
  </div>
  <div class="form-group focus" :class="disabled ? 'disabled' : ''" v-if="type == 'select'">
    <select :id="name" class="form-select" v-model="inputVal" :disabled="disabled">
      <option disabled selected :value="$t('select')">
        {{ $t("select") }}
      </option>
      <option :value="option" v-for="option in options" :key="option">
        {{ option }}
      </option>
    </select>
    <label :for="name">{{ label }}</label>
  </div>
  <div class="form-group focus" :class="disabled ? 'disabled' : ''" v-if="type == 'selectID'">
    <select :id="name" class="form-select" v-model="inputVal" :disabled="disabled">
      <option disabled selected :value="$t('select')">
        {{ $t("select") }}
      </option>
      <option :value="option.value" v-for="option in options" :key="option">
        {{ option.name }}
      </option>
    </select>
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
export default {
  emits: ["inputVal"],
  props: {
    label: String,
    type: String,
    inputId: String,
    placeholder: String,
    value: String,
    name: String,
    options: Array,
    disabled: Boolean,
    masked: String,
    maskedMax: String,
    hiderange: Boolean,
  },
  data() {
    return {
      inputVal: this.value,
      number: {
        mask: "#".repeat(this.maskedMax),
        tokens: {
          "#": { pattern: /[0-9]/ },
        },
      },
      text: {
        mask: "A".repeat(this.maskedMax),
        tokens: {
          A: { pattern: /[a-zA-ZıİüÜğĞşŞçÇöÖ]/ },
        },
      },
      phone: {
        mask: "5##########",
        tokens: {
          "#": { pattern: /[0-9]/ },
        },
      },
      any: {
        mask: "A".repeat(this.maskedMax),
        tokens: {
          A: { pattern: /[a-zA-ZıİüÜğĞşŞçÇöÖ0-9.\s/-]/ },
        },
      },
    };
  },
  watch: {
    value(newValue) {
      this.inputVal = newValue;
    },
    inputVal(newInputVal) {
      this.$emit("inputVal", this.name, newInputVal);
    },
  },
  methods: {
    phoneMask() {
    let numara = this.inputVal;

    numara = numara.replace(/\D/g, "");

    if (numara.startsWith("+90")) {
      numara = numara.slice(3);
    } else if (numara.startsWith("90")) {
      numara = numara.slice(2);
    } else if (numara.startsWith("0")) {
      numara = numara.slice(1);
    }

    if (!numara.startsWith("5")) {
      numara = "5" + numara;
    }

    numara = numara.slice(0, 10);

    this.inputVal = numara;
  }
  },
};
</script>
