function convertDate(date){    
    date = date.toString()

    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6);

    const newDate = day + "." + month + "." + year;

    return newDate;
}

export { convertDate }