import axios from 'axios';
import env from '../config';
import store from '../store'
import curlirize from 'axios-curlirize';

function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return null;
}

//curlirize(axios);

axios.interceptors.request.use(
    config => {
        const csrftoken = getCookie('csrftoken');

        return {
            ...config,
            withCredentials: true,
            headers: {
                'Referer': 'https://serviskayit.viessmann.com.tr',
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
            }
        };
    },
    error => Promise.reject(error)
);

axios.defaults.baseURL = env.API_BASE_URL;

export default axios