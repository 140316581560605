import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        serial_number: "Serial Number",
        in_serial_number:"In Serial Number",
        out_serial_number:"Out Serial Number",
        continue: "Continue",
        select:"Select",
        cancel: "Cancel",
        confirm_and_continue:"Confirm Code and Continue",
        tckn: "TCKN",
        vkn: "VKN",
        phone: "Phone Number",
        given_name: "Name",
        family_name: "Surname",
        email: "Email",
        company: "Company",
        verification_code: "Verification Code",
        kvkk: "KVKK Information Text",
        kvkk_end: "I have read the",
        message_permission: "Sending me commercial electronic messages (SMS, e-mail, call);",
        approve: "I approve",
        decline: "I do not approve",
        campaign_code: "Campaign Code",
        campaign_code_number: "Phone",
        no_campaign_code: "I don't have a campaign code",
        city: "city",
        town: "town",
        neighborhood: "Neighborhood",
        boulevard: "Boulevard",
        street: "Street",
        zip_code: "Zip Code",
        building_name: "Building Name",
        building_number: "Building Number",
        floor: "Floor Number",
        building: "Building",
        same_address: "My Contact Address and Installation Address are the same",
        note: "Your Note",
        request_created: "Request Created!",
        request_created_message: "Your request has reached us. Our teams will respond to your request as soon as possible.",
        request_number: "Request Number",
        no_request_number: "Your request code could not be generated due to system error.",
        connect_message: "For your questions, you can contact the Viessmann Support Line.",
        email_address: "Email Address",
        call_center: "Call Center",
        back_home: "Return to Home Page",
        send_verification_code: "Send Verification Code",
        resend_verification_code: "Resend Verification Code",
        send_startup_request: "Submit Commissioning Request",
        send_support_request: "Submit a Support Request",
        app_title: "Service Registration",
        device_detail_info_title: "Device Information",
        device_detail_docs_title: "Device Documents",
        warranty_start_date: "Warranty Start Date",
        warranty_end_date: "Warranty End Date",
        create_startup_request: "Create Startup Request",
        create_support_request: "Create Support Request",
        end_user_login: "User Login",
        partner_plus_login: "End User Registration",
        new_partner_plus_member_login: "Partner Plus Membership",
        new_partner_plus_slogan: "By becoming a Viessmann Partner Plus member, you can enter the profitable world of Viessmann.",
        member_login: "Member Login",
        step_title: "End Member Information",
        step_slogan: "Fill in the form fields to proceed to the next step.",
        step_technician_information: "Technician Information",
        step_customer_information: "Customer Information",
        step_verification: "Security Verification",
        step_campaign: "Campaign Code",
        step_contact_address: "Contact Address",
        step_installation_address: "Installation Address",
        step_message_us: "Message Us",
        step_success: "Request Created",
        step_request_text: "Request Text",
        step_customer_slogan: "Please enter your customer information.",
        step_technician_slogan: "Please enter your technician information.",
        step_verification_slogan: "To continue the transaction, enter the verification code sent to the phone number you entered.",
        step_support_verification_slogan: "Before submitting your support request, you must perform security verification through your registered phone number.",
        step_campaign_slogan: "Enter the current 10-digit campaign code.",
        step_contact_address_slogan: "Please enter contact address.",
        step_installation_address_slogan: "Please enter the installation address.",
        step_message_us_slogan: "Write the note you want to send us.",
        step_request_text_slogan: "Please write the problem you are experiencing.",
        routine:"Routine",
        repair:"Repair",
        request_type:"Request Type",
        guaranty_not_started:"Guaranty not started.",
        guaranty_not_finished:"Guaranty continues.",
        guaranty_finished:"Guaranty finished.",
        resend_code:"Resend",
        serialnotfound:"Serial Number not found. Please check.",
        back_to_start:"Back To Start"

    },
    tr: {
        serial_number: "Seri Numarası",
        in_serial_number:"İç Ünite Seri Numarası",
        out_serial_number:"Dış Ünite Seri Numarası",
        continue: "Devam Et",
        cancel: "İptal Et",
        confirm_and_continue:"Kodu Onayla ve Devam Et",
        select:"Seçiniz",
        tckn: "TCKN",
        vkn: "VKN",
        phone: "Telefon Numarası",
        given_name: "İsim",
        family_name: "Soyisim",
        app_title: "Servis Kayıt",
        email: "E-Posta Adresi",
        company: "Firma Adı",
        verification_code: "Doğrulama Kodu",
        kvkk: "KVKK Aydınlatma Metni",
        kvkk_end: "'ni okudum.",
        message_permission: "Tarafıma ticari elektronik ileti (SMS, e-posta, çağrı) gönderilmesini;",
        approve: "Onaylıyorum",
        decline: "Onaylamıyorum",
        campaign_code: "Kampanya Kodu",
        campaign_code_number: "Telefon",
        no_campaign_code: "Kampanya kodum yok",
        city: "İl",
        town: "İlçe",
        neighborhood: "Mahalle",
        boulevard: "Cadde",
        street: "Sokak",
        zip_code: "Posta Kodu",
        building_name: "Bina Adı",
        building_number: "Bina No",
        floor: "Kat Numarası",
        building: "Daire No",
        same_address: "İletişim Adresim  ve Kurulum Adresim aynı",
        note: "Notunuz",
        request_created: "Talep Oluşturuldu!",
        request_created_message: "Talebiniz bize ulaşmıştır. Ekiplerimiz en kısa sürede talebinizi yanıtlayacaktır.",
        request_number: "Talep Numaranız",
        no_request_number: "Sistem hatası dolayısıyla talep kodunuz üretilemedi.",
        connect_message: "Sorularınız için Viessmann Destek Hattı'na ulaşabilirsiniz.",
        email_address: "E-posta Adresi",
        call_center: "Çağrı Merkezi",
        back_home: "Anasayfaya Dön",
        send_verification_code: "Doğrulama Kodu Gönder",
        resend_verification_code: "Kodu Tekrar Gönder",
        send_startup_request: "Devreye Alma Talebi Gönder",
        send_support_request: "Destek Talebi Gönder",
        device_detail_info_title: "Cihaz Bilgileri",
        device_detail_docs_title: "Cihaz Belgeleri",
        warranty_start_date: "Garanti Başlangıç Tarihi",
        warranty_end_date: "Garanti Bitiş Tarihi",
        create_startup_request: "Devreye Alma Talebi Oluştur",
        create_support_request: "Destek Talebi Oluştur",
        end_user_login: "Kullanıcı Girişi",
        partner_plus_login: "Son Kullanıcı Kaydı",
        new_partner_plus_member_login: "Partner Plus Üyeliği",
        new_partner_plus_slogan: "Viessmann Partner Plus üyesi olup, Viessmann'ın kazandıran dünyasına giriş yapabilirsiniz.",
        member_login: "Üye Girişi",
        step_title: "Son Kullanıcı Bilgileri",
        step_slogan: "Bir sonraki adıma geçmek için form alanlarını doldurunuz.",
        step_technician_information: "Teknisyen Bilgileri",
        step_customer_information: "Müşteri Bilgileri",
        step_verification: "Güvenlik Doğrulaması",
        step_campaign: "Kampanya Kodu",
        step_contact_address: "İletişim Adresi",
        step_installation_address: "Cihaz Adresi",
        step_message_us: "Bize İletin",
        step_success: "Talep Oluşturuldu",
        step_request_text: "Talep Metni",
        step_customer_slogan: "Lütfen müşteri bilgilerinizi giriniz.",
        step_technician_slogan: "Lütfen teknisyen bilgilerinizi giriniz.",
        step_verification_slogan: "İşleme devam edebilmek için girmiş olduğunuz telefon numarasına gelen doğrulama kodunu giriniz.",
        step_support_verification_slogan: "Destek talebinizi iletmeden önce kayıtlı telefon numaranız üzerinden güvenlik doğrulaması yapmanız gerekmektedir.",
        step_campaign_slogan: "Mevcut 10 haneli kampanya kodunu giriniz.",
        step_contact_address_slogan: "Lütfen iletişim adresini giriniz.",
        step_installation_address_slogan: "Lütfen  kurulum adresini giriniz.",
        step_message_us_slogan: "Bize iletmek istediğiniz notu yazınız.",
        step_request_text_slogan: "Lütfen yaşadığınız problemi yazınız.",
        routine:"Rutin Bakım",
        repair:"Arıza",
        request_type:"Talep Tipi",
        guaranty_not_started:"Cihazınızın garantisi henüz başlamamıştır.",
        guaranty_not_finished:"Cihazınızın garantisi devam ediyor.",
        guaranty_finished:"Cihazınızın garantisi bitmiştir.",
        resend_code:"Tekrar Gönder",
        serialnotfound:"Seri Numarası bulunamadı. Lütfen kontrol edin.",
        back_to_start:"Başlangıca Dön"
    },
};

const i18n = createI18n({
    locale: 'tr',
    messages,
});

export default i18n