import { createStore } from 'vuex';
import route from '../router'
import axios from '../helpers/interceptors';
import env from '../config'

async function setStorageFunc(storageName, storageValue) {
    localStorage.setItem(storageName, storageValue)
}
async function getStorageFunc(storageName) {
    return localStorage.getItem(storageName)
}

async function getCookieFunc(name) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName == name) {
            return cookieValue;
        }
    }
    return null;
}

async function cleanObject(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === null || obj[key] === '' ||
                (Array.isArray(obj[key]) && obj[key].length === 0) ||
                (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) ||
                typeof obj[key] === 'undefined') {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                cleanObject(obj[key]); // Recursive call for nested objects
            }
        }
    }
    return obj;
}



const store = createStore({
    state() {
        return {
            reloaded: true,
            serialnumber: "",
            serialnumber_2: "",
            is_double_serial: false,
            ticket_type: "",
            step: 1,
            customer_information: {
                tckn: "",
                phone: "",
                given_name: "",
                family_name: "",
                email: "",
                company: ""
            },
            technician_information: {
                tckn: "",
                phone: "",
                given_name: "",
                family_name: "",
                email: "",
                company: ""
            },
            contact_address: {
                city: "Seçiniz",
                town: "Seçiniz",
                neighborhood: "Seçiniz",
                boulevard: "",
                street: "",
                zip_code: "",
                building_name: "",
                building_number: "",
                floor: "",
                building: "",
            },
            installation_address: {
                city: "Seçiniz",
                town: "Seçiniz",
                neighborhood: "Seçiniz",
                boulevard: "",
                street: "",
                zip_code: "",
                building_name: "",
                building_number: "",
                floor: "",
                building: "",
            },
            verification_code: "",
            kvkk: false,
            message_permission: "",
            campaign_code: "",
            campaign_phone: "",
            campaignMismatchError: "",
            no_campaign_code: false,
            same_address: false,
            note: "",
            support: {
                note: "",
                request_type: "",
            },
            device_details: {},
            device_docs: [],
            image_url: "",
            device_type: "",
            installation_included: "",
            notMatchSerial: false,
            serialErrorMsg: "",
            loading: false,
            serialnotfound: false,
            serial2notfound: false
        };
    },
    actions: {
        async setSerialnumber({ dispatch, commit, state }, data) {
            let serial = data.serial
            let serial2 = data.serial2

            state.serialnotfound = false
            state.serial2notfound = false

            async function setDeviceInformation(data) {
                state.device_details = data.device_details
                state.device_docs = data.device_docs
                state.installation_included = data.installation_included
                state.image_url = data.image_url.images.length > 0 ? data.image_url.images[0].url : ""
                state.device_type = data.device_type
            }

            if (state.is_double_serial) {
                state.loading = true;
                state.notMatchSerial = false
                const outdeviceResponse = await axios.get(`/api/device/${serial}/`)
                const indeviceResponse = await axios.get(`/api/device/${serial2}/`)
                const outZK = outdeviceResponse.data.device_z_code
                const inZK = indeviceResponse.data.device_z_code
                const outdeviceType = outdeviceResponse.data.device_type
                const indeviceType = indeviceResponse.data.device_type

                if (outdeviceResponse.data.device_details.statusCode == 400) {
                    state.serialnotfound = true
                    state.loading = false
                } else {
                    if (outdeviceType == "idu") {
                        state.serialnumber = serial2
                        state.serialnumber_2 = serial
                    } else {
                        state.serialnumber = serial
                        state.serialnumber_2 = serial2
                    }
                }

                if (indeviceResponse.data.device_details.statusCode == 400) {
                    state.serial2notfound = true
                    state.loading = false
                } else {
                    if (outdeviceType == "idu") {
                        state.serialnumber = serial2
                        state.serialnumber_2 = serial
                    } else {
                        state.serialnumber = serial
                        state.serialnumber_2 = serial2
                    }
                }

                if (outdeviceResponse.data.device_details.statusCode != 400 && indeviceResponse.data.device_details.statusCode != 400) {

                    if (outdeviceType === indeviceType) {
                        commit("setSerialError", "Lütfen bir iç bir dış seri numarası giriniz.")
                        state.loading = false
                    }
                    else {
                        await axios.get("/api/whoami/")
                            .then(async () => {
                                if (outZK !== inZK) {
                                    state.serialErrorMsg = "Seri numaraları eşleşmemektedir."
                                    state.notMatchSerial = true
                                } else {

                                    let data = outdeviceType == "idu" ? indeviceResponse.data : outdeviceResponse.data
                                    await setDeviceInformation(data)
                                    route.push('/device-detail')
                                }
                                state.loading = false
                            })
                            .catch(error => {
                                route.push("/login-redirect")
                            })

                    }
                }

            }
            else {
                state.loading = true;
                await axios.get('/api/device/' + serial + "/")
                    .then(async (response) => {
                        let data = response.data

                        if (data.device_details.statusCode == 400) {
                            state.serialnotfound = true
                        }
                        else if (data.device_type != "solo") {

                            if (data.device_type == "idu" && data.device_details.guarantyEndDate <= 0) {
                                state.serialnumber = serial2
                                state.serialnumber_2 = serial
                            }
                            else {
                                state.serialnumber = serial
                            }

                            await setDeviceInformation(data)

                            if (data.device_details.guarantyEndDate > 0) {
                                route.push('/device-detail')
                            }
                            else {
                                state.is_double_serial = true;
                            }
                        }
                        else {
                            state.serialnumber = serial
                            await setDeviceInformation(data)
                            route.push('/device-detail')
                        }
                        state.loading = false
                    })
            }


            if (state.device_details.guarantyEndDate > 0) {
                state.ticket_type = "support"
                state.serialnumber = serial
            }
            else {
                state.ticket_type = "startup"
            }

            await dispatch("checkAuth")
        },

        async checkAuth(context, last) {
            const storage = await getStorageFunc("prsc")
            const csrftoken = await getCookieFunc("csrftoken")

            if (!context.state.reloaded) {
                context.commit("setStorage", last);
            } else {
                const parsedStorage = JSON.parse(storage)
                context.commit("setReloaded", false);
                parsedStorage.state.last = parsedStorage.last
                context.commit("setState", parsedStorage.state)
                route.push(parsedStorage.last);
            }
        },

        setStep({ commit, state }, data) {
            commit("set_step", data)
            commit("setState")
        },

        setCustomerInformation({ dispatch, commit, state }, data) {
            state.customer_information = data
        },
        setTechnicianInformation({ commit, state }, data) {
            state.technician_information = data
        },
        async setVerification({ commit, dispatch, state }, data) {
            state.verification_code = data.verification_code
            state.kvkk = data.kvkk
            state.message_permission = data.message_permission

            const response = await axios.get("/api/whoami/")
            const user = response.data
            if (user.identity_type == "consumer" && state.ticket_type == "support") {
                await dispatch("sendSupportTicket")
            }
            state.loading = false
        },

        setCampaign({ commit, state }, data) {
            state.campaign_code = data.campaign_code
            state.no_campaign_code = data.no_campaign_code
            state.campaign_phone = data.campaign_phone
        },
        setContactAddress({ commit, state }, data) {
            state.contact_address = data
            state.loading = false
        },

        setInstallationAddress({ commit, state }, data) {
            state.installation_address = data.address
            state.same_address = data.check
            state.loading = false
        },

        async sendStartupRequest({ commit, state }, data) {
            state.note = data
            const response = await axios.get("/api/whoami/")
            const user = response.data
            let payload = {
                "username": user.username,
                "user_given_name": user.given_name,
                "user_family_name": user.family_name,
                "user_email": user.email,
                "user_identity_type": user.identity_type,
                "user_viessmann_id": user.viessmann_id,
                "user_mobile_number":
                    user.identity_type == "consumer"
                        ? state.customer_information.phone
                        : user.identity_type == "lite-partner"
                            ? state.technician_information.phone
                            : user.identity_type == "partner" || user.identity_type == 'internal' || user.identity_type == 'external'
                                ? "" : "",
                "user_tax_id":
                    user.identity_type == "consumer"
                        ? state.customer_information.tckn
                        : user.identity_type == "lite-partner"
                            ? state.technician_information.tckn
                            : "",
                "user_company_name":
                    user.identity_type == "consumer"
                        ? state.customer_information.company
                        : user.identity_type == "lite-partner"
                            ? state.technician_information.company
                            : "",

                "device_serial_number": state.serialnumber,
                "device_material_number": state.device_details.serialMaterialNumber,
                "device_type": state.device_type,
                "device_installation_included": state.installation_included,

                "device_additional_serial_numbers": state.device_type != "solo" ? [state.serialnumber_2] : "",

                "device_city": state.installation_address.city,
                "device_town": state.installation_address.town,
                "device_neighborhood": state.installation_address.neighborhood,
                "device_postal_code": state.installation_address.zip_code,
                "device_boulevard": state.installation_address.boulevard,
                "device_street": state.installation_address.street,
                "device_building_number": state.installation_address.building_number,
                "device_building_name": state.installation_address.building_name,
                "device_floor_number": state.installation_address.floor,
                "device_door_number": state.installation_address.building,


                "customer_given_name": state.customer_information.given_name,
                "customer_family_name": state.customer_information.family_name,
                "customer_mobile_number": state.customer_information.phone,
                "customer_tax_id": state.customer_information.tckn,
                "customer_email": state.customer_information.email,
                "customer_company_name": state.customer_information.company,

                "customer_city": state.contact_address.city,
                "customer_town": state.contact_address.town,
                "customer_neighborhood": state.contact_address.neighborhood,
                "customer_postal_code": state.contact_address.zip_code,
                "customer_boulevard": state.contact_address.boulevard,
                "customer_street": state.contact_address.street,
                "customer_building_number": state.contact_address.building_number,
                "customer_building_name": state.contact_address.building_name,
                "customer_floor_number": state.contact_address.floor,
                "customer_door_number": state.contact_address.building,

                "campaign_voucher_code": state.no_campaign_code ? "" : state.campaign_code,
                "campaign_mobile_number": state.no_campaign_code ? "" : state.campaign_phone,
                "ticket_notes": data,
                "ordering_partner_viessmann_id": state.device_details.orderingCustomer,
            }

            const cleanedObject = await cleanObject(payload)

            //console.log(cleanedObject)

            await axios.post("/api/startup-request/", cleanedObject)
                .then(succesTicket => {
                    let ticketNumber = succesTicket.data.sap_notification_number
                    route.push(`/startup/success?ticket=${ticketNumber}`);
                    state.step += 1;
                    state.loading = false
                })
                .catch((error) => {
                    route.push(`/error?error=${error.response.status}`);
                    console.log(error)
                    state.loading = false
                })
        },

        confirmCode({ commit, state }, data) {
            state.support.verification_code = data
        },

        async setSupportCustomerInformation({ commit, dispatch, state }, data) {
            let userresponse = await axios.get("/api/whoami/")
            let user = userresponse.data

            state.support.note = data.note
            state.support.request_type = data.request_type
            state.customer_information.tckn = data.tckn
            state.customer_information.phone = data.phone
            state.customer_information.given_name = data.given_name
            state.customer_information.family_name = data.family_name
            state.customer_information.email = data.email
            state.customer_information.company = data.company

            if (user.identity_type != "consumer") {
                await dispatch("sendSupportTicket")
            }
        },

        async sendSupportTicket({ state }) {
            let userresponse = await axios.get("/api/whoami/")
            let user = userresponse.data
            let payload = {
                "username": user.username,
                "user_given_name": user.given_name,
                "user_family_name": user.family_name,
                "user_email": user.email,
                "user_identity_type": user.identity_type,
                "user_viessmann_id": user.viessmann_id,
                "user_mobile_number":
                    user.identity_type == "consumer"
                        ? state.customer_information.phone
                        : user.identity_type == "lite-partner"
                            ? state.technician_information.phone
                            : user.identity_type == "partner" || user.identity_type == 'internal' || user.identity_type == 'external'
                                ? "" : "",
                "device_serial_number": state.serialnumber,
                "device_material_number": state.device_details.serialMaterialNumber,
                "device_type": state.device_type,
                "ticket_type": state.support.request_type,
                "customer_given_name": state.customer_information.given_name,
                "customer_family_name": state.customer_information.family_name,
                "customer_mobile_number": state.customer_information.phone,
                "customer_email": state.customer_information.email,
                "ticket_notes": state.support.note,
            }

            const cleanedObject = await cleanObject(payload)

            //console.log(cleanedObject)

            await axios.post("/api/support-request/", cleanedObject)
                .then(response => {
                    let ticketNumber = response.data.sap_notification_number
                    route.push(`/support/success?ticket=${ticketNumber}`);
                    state.step += 1;
                })
        },

        backHome({ commit }) {
            commit("resetState")
            route.push("/")
        },
    },
    mutations: {
        resetState(state) {
            let emptyState = {
                reloaded: false,
                serialnumber: "",
                serialnumber_2: "",
                is_double_serial: false,
                ticket_type: "",
                step: 1,
                customer_information: {
                    tckn: "",
                    phone: "",
                    given_name: "",
                    family_name: "",
                    email: "",
                    company: ""
                },
                technician_information: {
                    tckn: "",
                    phone: "",
                    given_name: "",
                    family_name: "",
                    email: "",
                    company: ""
                },
                contact_address: {
                    city: "Seçiniz",
                    town: "Seçiniz",
                    neighborhood: "Seçiniz",
                    boulevard: "",
                    street: "",
                    zip_code: "",
                    building_name: "",
                    building_number: "",
                    floor: "",
                    building: "",
                },
                installation_address: {
                    city: "Seçiniz",
                    town: "Seçiniz",
                    neighborhood: "Seçiniz",
                    boulevard: "",
                    street: "",
                    zip_code: "",
                    building_name: "",
                    building_number: "",
                    floor: "",
                    building: "",
                },
                verification_code: "",
                kvkk: false,
                message_permission: "",
                campaign_code: "",
                campaign_phone: "",
                campaignMismatchError: "",
                no_campaign_code: false,
                same_address: false,
                note: "",
                support: {
                    phone: "",
                    verification_code: "",
                    note: ""
                },
                device_details: {},
                device_docs: [],
                image_url: "",
                device_type: "",
                installation_included: "",
                last: "",
                notMatchSerial: false,
                serialErrorMsg: "",
                loading: false,
                serialnotfound: false,
                serial2notfound: false
            }
            setStorageFunc("prsc", JSON.stringify(emptyState))
            Object.assign(state, emptyState)
        },

        async set_step(state, data) {
            Object.assign(state.step, data)
        },
        async setState(state, data) {
            await Object.assign(state, data)
        },
        async setStorage(state, last) {
            state.last = last
            await setStorageFunc("prsc", JSON.stringify({
                last: last,
                state: { ...state }
            }))
        },
        setReloaded(state) {
            state.reloaded = false
        },
        setSerialError(state, msg) {
            state.notMatchSerial = true
            state.serialErrorMsg = msg
        }
    },
    getters: {
        step(state) {
            return state.step
        }
    },
});



export default store;