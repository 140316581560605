import { createRouter, createWebHistory } from 'vue-router';
import axios from '../helpers/interceptors'
import store from '../store'

const routes = [
  {
    path: '/',
    redirect: '/serial',
    component: () => import(/* webpackChunkName: "layout" */'../components/Layout.vue'),
    children: [
      {
        path: 'serial',
        component: () => import(/* webpackChunkName: "serial" */ "../pages/SerialNumber.vue"),
      },
      {
        path: 'device-detail',
        component: () => import(/* webpackChunkName: "device-detail" */ "../pages/DeviceDetail.vue"),
      },
      {
        path: 'login-redirect',
        component: () => import(/* webpackChunkName: "login-redirect" */ "../pages/LoginRedirect.vue"),
      },
      {
        path: 'startup',
        component: () => import(/* webpackChunkName: "startup" */ "../pages/startup/StartUp.vue"),
        children: [
          {
            path: 'customer-information',
            component: () => import(/* webpackChunkName: "customer-information" */ "../pages/startup/CustomerInformation.vue"),
          },
          {
            path: 'technician-information',
            component: () => import(/* webpackChunkName: "technician-information" */ "../pages/startup/TechnicianInformation.vue"),
          },
          {
            path: 'security-verification',
            component: () => import(/* webpackChunkName: "startup-verification" */ "../pages/startup/StartupVerification.vue"),
          },
          {
            path: 'campaign',
            component: () => import(/* webpackChunkName: "campaign" */ "../pages/startup/Campaign.vue"),
          },
          {
            path: 'contact-address',
            component: () => import(/* webpackChunkName: "contact-address" */ "../pages/startup/ContactAddress.vue"),
          },
          {
            path: 'installation-address',
            component: () => import(/* webpackChunkName: "installation-address" */ "../pages/startup/InstallationAddress.vue"),
          },
          {
            path: 'message-us',
            component: () => import(/* webpackChunkName: "message-us" */ "../pages/startup/MessageUs.vue"),
          },
          {
            path: 'success',
            component: () => import(/* webpackChunkName: "success" */ "../pages/Success.vue"),
          }
        ]
      },
      {
        path: 'support',
        component: () => import(/* webpackChunkName: "support" */ "../pages/support/Support.vue"),
        children: [
          {
            path: 'security-verification',
            component: () => import(/* webpackChunkName: "security-verification" */ "../pages/support/SupportVerification.vue"),
          },
          {
            path: 'customer-information',
            component: () => import(/* webpackChunkName: "customer-information" */ "../pages/support/CustomerInformation.vue"),
          },
          {
            path: 'technician-information',
            component: () => import(/* webpackChunkName: "technician-information" */ "../pages/support/TechnicianInformation.vue"),
          },
          {
            path: 'success',
            component: () => import(/* webpackChunkName: "success" */ "../pages/Success.vue"),
          },
        ]
      }
    ]
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error" */ "../pages/Error.vue"),
  },
  {
    path: '/typography',
    component: () => import(/* webpackChunkName: "typography" */ "../pages/Typography.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  try {
    await store.dispatch('checkAuth', to.fullPath);
    next();
  } catch (error) {
    next();
  }
});

export default router