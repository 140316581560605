<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  created() {
    
  },
};
</script>

<style lang='scss'>
@import "./assets/sass/app.scss";
</style>
